import React from 'react'
import { graphql } from 'gatsby'
import Alert from 'react-bootstrap/Alert'

import Layout from '../../components/layout'
import { Announcement } from '../../components/job'

const JobAnnouncementPage = ({ data }) => {
  const job = data.strapiJob
  const { open } = job
  return (
    <Layout tocEnabled={false} objectInfo={job}>
      {!open ? (
        <Alert>
          This competition has been closed. No new applications can be
          submitted.
        </Alert>
      ) : null}
      <Announcement job={job} />
    </Layout>
  )
}

export const jobAnnouncementPageQuery = graphql`
  query JobAnnouncementQuery($id: Int) {
    strapiJob(strapi_id: { eq: $id }) {
      __typename
      strapi_id
      updatedAt
      title
      slug
      open
      position
      inspires
      deadline
      description {
        data {
          childMarkdownRemark {
            htmlAst
          }
        }
      }
    }
  }
`

export default JobAnnouncementPage
